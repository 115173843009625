<template>
  <div>
    <div class="breadcrumb">
      <slot name="header">
        <h1>Backups</h1>
        <ul>
          <li v-for="breadcrumb in breadcrumbs" :key="breadcrumb.directory">
            <router-link :to="'/app/backups/' + breadcrumb.directory">{{ breadcrumb.display }}</router-link>
          </li>
        </ul>
      </slot>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <b-table-simple>
      <b-thead>
        <tr>
          <th>Contents</th>
        </tr>
      </b-thead>

      <b-tbody v-if="loading">
        <tr>
          <td><div class="spinner-bubble spinner-bubble-warning m-5"></div></td>
        </tr>
      </b-tbody>

      <b-tbody v-else>
        <tr>
          <td>
            <b-form-input id="search" placeholder="filter" v-model="filter"></b-form-input>
          </td>
        </tr>
        <tr v-for="item in items" :key="item.id">
          <td>
            <!--            <router-link :to="{ name: 'backups', params: { directory: item.id } }">{{ item.label }}</router-link>-->
            <router-link v-if="item.children !== undefined" :to="$route.path + '/' + item.label"
              ><i class="i-Folder"></i> {{ item.label }}</router-link
            >
            <div v-else>
              <a href="" @click.prevent="selectFile(item)"
                ><i class="i-File"></i> {{ item.label }}
                {{ `(${item.versions.length} version${item.versions.length === 1 ? "" : "s"})` }}</a
              >
            </div>
            <!--            <a :href="decodeURIComponent($router.resolve({ name: 'backups', params: { directory: item.id } }).href)">{{-->
            <!--              item.label-->
            <!--            }}</a>-->
          </td>
        </tr>
      </b-tbody>
    </b-table-simple>
    <b-modal
      id="fileModal"
      centered
      ok-only
      ok-title="Close"
      :title="selectedFile !== null ? selectedFile.label : ''"
      size="lg"
    >
      <div v-if="selectedFile !== null">
        <h3>Versions</h3>
        <ul>
          <li v-for="version in selectedFile.versions" :key="version.versionId">
            <a @click.prevent="download(version)" href="">
              {{ moment(version.dateModified.date).format("YYYY-MM-DD HH:mm:ss") }}
              {{ version.isLatest ? " (latest)" : "" }}
            </a>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, computed } from "@vue/composition-api";
import moment from "moment";

import ApiClient from "@/ApiClient";

export default defineComponent({
  name: "Backups",
  components: {},
  props: {
    directory: {
      type: String,
      default: ""
    }
  },
  setup(props, { root }) {
    const value = ref(null);
    const options = ref([]);
    const selectedFile = ref(null);
    const loading = ref(false);
    const filter = ref("");

    const displayDirectory = computed(() => {
      return props.directory.slice(-1) === "/"
        ? props.directory.substr(0, props.directory.length - 1)
        : props.directory;
    });

    function loadDirectory(directory) {
      options.value = [];
      loading.value = true;
      ApiClient.get("/api/s3", {
        params: {
          dir: directory
        }
      }).then(response => {
        const dirs = response.data.directories;
        dirs.forEach(dir => {
          options.value.push({
            id: dir.slice(-1) === "/" ? dir.substr(0, dir.length - 1) : dir,
            label: (dir.slice(-1) === "/" ? dir.substr(0, dir.length - 1) : dir).replace(directory + "/", ""),
            children: null
          });
        });

        const files = response.data.files;

        files.forEach(file => {
          options.value.push({
            id: file.key.slice(-1) === "/" ? file.substr(0, file.length - 1) : file.key,
            label: file.key.replace(directory + "/", ""),
            versions: file.versions
          });
        });
        loading.value = false;
      });
    }

    const items = computed(() => {
      return options.value.filter(item => {
        if (filter.value === "") {
          return true;
        }
        return item.id.toLowerCase().includes(filter.value.toLowerCase());
      });
    });

    watch(
      () => props.directory,
      newValue => {
        loadDirectory(newValue);
      }
    );

    onMounted(() => {
      loadDirectory(displayDirectory.value);
    });

    function selectFile(file) {
      selectedFile.value = file;
      root.$bvModal.show("fileModal");
    }

    const breadcrumbs = computed(() => {
      const dirs = displayDirectory.value.split("/");

      const breadcrumbs = [];
      dirs.forEach((dir, index) => {
        let fullPath = "";

        for (let i = 0; i < index; i++) {
          fullPath += dirs[i] + "/";
        }
        fullPath += dir;
        breadcrumbs.push({ directory: fullPath, display: dir });
      });
      return breadcrumbs;
    });

    function download(version) {
      ApiClient.get("/api/s3/download", {
        params: {
          key: selectedFile.value.id,
          version: version.versionId
        }
      }).then(response => {
        const data = response.data;

        const versionDate = moment(version.dateModified.date).format("YYYY-MM-DD_HHmmss");

        const filename = selectedFile.value.label;
        const filenameParts = filename.split(".");
        filenameParts.splice(-1, 0, "_" + versionDate);
        const datedFilename = filenameParts.join(".");

        if (navigator.msSaveBlob) {
          // IE 10+
          const blob = new Blob([data]);
          navigator.msSaveBlob(blob, datedFilename);
        } else {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));

          const link = document.createElement("a");
          link.setAttribute("href", downloadUrl);
          link.setAttribute("download", datedFilename);
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    }

    return {
      items,
      value,
      displayDirectory,
      selectFile,
      breadcrumbs,
      selectedFile,
      loading,
      moment,
      download,
      filter
    };
  }
});
</script>

<style scoped></style>
